<template>
  <div id="app">
    <!--<coming-soon></coming-soon>-->
   <Website></Website>
  </div>
</template>

<script>

import Website from "./components/Website";
//import ComingSoon from "./components/ComingSoon";

export default {
  name: 'App',
  components:{
    Website,
    //ComingSoon
  }
}
</script>

<style>
  /*@import './assets/css/tw.min.css';*/
</style>
