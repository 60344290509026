import Vue from 'vue';
import App from './App.vue';
// import './assets/css/tailwind.css'
import './assets/css/tw.min.css';
import VueRouter from 'vue-router'

Vue.config.productionTip = false;

import vueScrollto from 'vue-scrollto';
import Website from "./components/Website";
import VueResource from 'vue-resource'


Vue.use(vueScrollto);

Vue.use(VueRouter);
Vue.use(VueResource);

const router = new VueRouter({
  mode: 'history',
  routes: [
    // dynamic segments start with a colon
    { path: '/:client/', component: Website },
    { path: '/:client/:project', component: Website }
  ]
});

new Vue({
  render: h => h(App),
  router
}).$mount('#app');

