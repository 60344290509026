<template>
  <div>
    <section>
      <div class="container px-4 mx-auto">
        <nav class="flex justify-between items-center py-6">
          <a class="text-3xl font-semibold leading-none" href="#"><img
              src="../assets/imgs/logo.png" alt="logo" width="160"></a>
          <a class="text-3xl font-semibold leading-none" href="#"
             v-if="faqGroup && faqGroup.client && faqGroup.client.theme && faqGroup.client.theme.lightlogo">
            <img :src="faqGroup.client.theme.lightlogo.url" alt="logo" style="max-height: 70px; max-width: 160px"></a>
          <div class="lg:hidden">
            <button
                class="navbar-burger flex items-center py-2 px-3 text-blue-600 hover:text-blue-700 rounded border border-blue-200 hover:border-blue-300">
              <svg class="fill-current h-4 w-4" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Mobile
                menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
              </svg>
            </button>
          </div>
          <ul class="hidden lg:flex lg:items-center lg:w-auto lg:space-x-12">
            <li>
              <a class="text-sm text-blueGray-400 hover:text-blueGray-500" href="#" v-scroll-to="'#whatisfitt'">
                WHAT IS FITT?
              </a>
            </li>
            <li><a class="text-sm text-blueGray-400 hover:text-blueGray-500" href="#" v-scroll-to="'#faqs'">FAQs</a>
            </li>
            <li><a class="text-sm text-blueGray-400 hover:text-blueGray-500" href="#" v-scroll-to="'#contact'">CONTACT
              US</a></li>
            <li><a class="text-sm text-blueGray-400 hover:text-blueGray-500" href="https://fitt.ai">FITT WEBSITE</a>
            </li>
          </ul>
          <!--          <div class="hidden lg:block"><a-->
          <!--              class="mr-2 px-4 py-3 text-xs text-blue-600 hover:text-blue-700 font-semibold leading-none border border-blue-200 hover:border-blue-300 rounded invisible"-->
          <!--              href="#">Log In</a><a-->
          <!--              class="inline-block px-4 py-3 text-xs font-semibold leading-none bg-blue-600 hover:bg-blue-700 text-white rounded invisible"-->
          <!--              href="#">Sign Up</a>
          </div>-->
        </nav>
        <div class="flex flex-wrap items-center -mx-3" id="whatisfitt">
          <div class="w-full lg:w-1/2 px-3">
            <div class="py-12">
              <div class="max-w-lg lg:max-w-md mx-auto lg:mx-0 mb-8 text-center lg:text-left">
                <h2 class="text-3xl md:text-4xl mb-4 font-bold font-heading">
                  <span>What is </span>
                  <span class="text-blue-600">fitt?</span>
                  <span class=""> It's the fully integrated talent tool</span>
                </h2>
                <p class="text-blueGray-400 leading-relaxed">fitt is an assessment tool used by organizations to better
                  understand their employees. fitt is also used for professional individuals to learn more about
                  themselves and discover some of their strengths and weaknesses.</p>
              </div>
              <div class="text-center lg:text-left"><a
                  class="block sm:inline-block py-4 px-8 mb-4 sm:mb-0 sm:mr-3 text-xs text-white text-center font-semibold leading-none bg-blue-600 hover:bg-blue-700 rounded"
                  href="#" v-scroll-to="'#faqs'">FAQs</a><a
                  class="block sm:inline-block py-4 px-8 text-xs text-blueGray-500 hover:text-blueGray-600 text-center font-semibold leading-none bg-white border border-blueGray-200 hover:border-blueGray-300 rounded"
                  href="#" v-scroll-to="'#contact'">Contact Support</a></div>
            </div>
          </div>
          <div class="w-full lg:w-1/2 px-3 mb-12 lg:mb-0">
            <div class="lg:h-128 flex items-center justify-center">
              <img class="lg:max-w-lg"
                   src="../assets/imgs/illustrations/work-tv.png"
                   alt=""></div>
          </div>
        </div>
      </div>
      <div class="hidden navbar-menu relative z-50">
        <div class="navbar-backdrop fixed inset-0 bg-blueGray-800 opacity-25"></div>
        <nav
            class="fixed top-0 left-0 bottom-0 flex flex-col w-5/6 max-w-sm py-6 px-6 bg-white border-r overflow-y-auto">
          <div class="flex items-center mb-8">
            <a class="mr-auto text-3xl font-semibold leading-none" href="#">
              <img src="../assets/imgs/logo.png"
                   alt="logo" width="160"></a>
            <button class="navbar-close">
              <svg class="h-6 w-6 text-blueGray-400 cursor-pointer hover:text-blueGray-500"
                   xmlns="http://www.w3.org/2000/svg" fill="none" viewbox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
          </div>
          <div>
            <ul>
              <li class="mb-1"><a class="block p-4 text-sm text-blueGray-500 hover:bg-blue-50 hover:text-blue-600"
                                  href="#">WHAT IS FITT?</a></li>
              <li class="mb-1"><a class="block p-4 text-sm text-blueGray-500 hover:bg-blue-50 hover:text-blue-600"
                                  href="#">FAQs</a></li>
              <li class="mb-1"><a class="block p-4 text-sm text-blueGray-500 hover:bg-blue-50 hover:text-blue-600"
                                  href="#">CONTACT US</a></li>
              <li class="mb-1"><a class="block p-4 text-sm text-blueGray-500 hover:bg-blue-50 hover:text-blue-600"
                                  href="#">FITT WEBSITE</a></li>
            </ul>
            <!--            <div class="mt-4 pt-6 border-t border-blueGray-100 invisible"><a-->
            <!--                class="block px-4 py-3 mb-3 text-xs text-center font-semibold leading-none bg-blue-600 hover:bg-blue-700 text-white rounded"-->
            <!--                href="#">Sign Up</a>-->
            <!--              <a class="block px-4 py-3 mb-2 text-xs text-center text-blue-600 hover:text-blue-700 font-semibold leading-none border border-blue-200 hover:border-blue-300 rounded"-->
            <!--                  href="#">Log In</a>-->
            <!--            </div>-->
          </div>
          <!--          <div class="mt-auto invisible">-->
          <!--            <p class="my-4 text-xs text-blueGray-400">-->
          <!--              <span class="hidden">Get in Touch</span>-->
          <!--              <a class="text-blue-600 hover:text-blue-600 underline invisible" href="#">info@example.com</a>-->
          <!--            </p>-->
          <!--            <a class="inline-block px-1" href="#"><img src="../assets/imgs/icons/facebook-blue.svg" alt=""></a><a-->
          <!--              class="inline-block px-1" href="#"><img src="../assets/imgs/icons/twitter-blue.svg" alt=""></a><a-->
          <!--              class="inline-block px-1" href="#"><img src="../assets/imgs/icons/instagram-blue.svg" alt=""></a>-->
          <!--          </div>-->
        </nav>
      </div>
    </section>
    <h1 class="text-5xl mb-2 leading-tight font-bold font-heading text-center" v-if="faqGroup"
        v-html="faqGroup.intro_heading">
    </h1>

    <div class="container mx-auto">
      <p class="mb-2 text-lg text-blueGray-400 leading-relaxed
                 px-6 text-center mb-12" v-if="faqGroup" v-html="faqGroup.intro_description"></p>
    </div>

    <div class="container mx-auto flex items-center justify-center">
      <!-- div class="lg:h-128 flex items-center justify-center" -->
      <img class="lg:max-w-lg max-w-xs sm:max-w-xs md:max-w-md" src="../assets/imgs/illustrations/faq.svg" alt="">
      <!--/div-->
    </div>

    <section class="py-20 lg:bg-contain bg-top bg-no-repeat" id="faqs"
             :style="{'background-image': 'url(' + require('../assets/imgs/backgrounds/intersect.svg') + ')'}">

      <div class="container mx-auto px-4">
        <div class="max-w-sm mx-auto mb-12 text-center">
          <h2 class="mb-4 text-3xl md:text-4xl font-bold font-heading">Frequently Asked&nbsp;Questions</h2>
          <p class="text-sm md:text-base leading-loose text-blueGray-500">
            <span>Can’t find the answer you’re looking for? Write out to our </span>
            <a class="text-blue-600 hover:text-blue-700" href="#" v-scroll-to="'#contact'">customer support team.</a>
          </p>
        </div>
        <form class="flex justify-center max-w-3xl mx-auto mb-16">
          <input
              v-model="searchTerm"
              class="w-2/3 p-4 text-xs placeholder-blueGray-800 font-bold font-heading bg-white focus:border-gray-500 focus:outline-none rounded-l"
              placeholder="Search, find any question you want to ask...">
          <button class="pr-4 rounded-r bg-white text-blue-600">
            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                 xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
            </svg>
          </button>
        </form>
        <div class="max-w-3xl mx-auto">
          <ul class="space-y-4">
            <li class="px-6 py-4 bg-white rounded shadow" @contextmenu.prevent="openRightClickMenu(item)" v-for="(item,idx) in filteredFaqs" :key="'faq_'+idx"
                :id="item.faqid">
              <button
                  @click="toggleFaq(idx)"
                  class="w-full flex justify-between items-center text-left font-bold font-heading"
                  :class="{'hover:text-blueGray-700':!item.open,'text-blue-600 hover:text-blue-700':item.open}">
                <span v-html="highlightSearchTerm(item.question)"></span>
                <svg class="w-4 h-4 ml-2 text-blueGray-300" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
                </svg>
              </button>
              <p :class="{'hidden':!item.open}" class="mt-2 text-blueGray-400 font-normal leading-loose"
                 v-html="item.answer">
              </p>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <section id="contact">
      <div class="container px-4 mx-auto">
        <div class="px-8 md:px-16 rounded">
          <div class="flex flex-wrap-reverse -mx-8">
            <div class="w-full lg:w-1/2 px-8 flex justify-center text-center lg:text-left">
              <div class="my-auto">
                <h2 class="text-3xl lg:text-4xl font-semibold">Still couldn't find an answer?</h2>
                <p class="mt-2 mb-6 text-blueGray-500 leading-loose">Contact our support team and we'll help you as best
                  as we can</p>
                <a class="inline-block py-4 px-8 text-xs border bg-white text-blue-600 font-semibold leading-none hover:bg-blue-600 hover:border-white hover:text-white rounded transition duration-300"
                   target="_blank"
                   href="mailto:support.fitt@psychai.co?subject=Support request from fitt faq website&body=Good day,%0D%0A%0D%0ACan you please assist with the following:%0D%0A%0D%0A">Contact
                  Support</a>
              </div>
            </div>
            <div class="w-full lg:w-1/2 px-8 mt-12 lg:mt-0 mb-16 lg:mb-20">
              <div class="relative max-w-sm sm:max-w-md mx-auto">
                <!--                <div class="absolute bg-blue-600" style="top: 22.8%; left: 3%; width: 25.2%; height: 72%;"><img class="object-cover w-full h-full" src="https://images.unsplash.com/photo-1535223289827-42f1e9919769?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=668&amp;q=80" alt=""></div>-->
                <!--                <div class="absolute bg-blue-600" style="top: 3.8%; left: 37%; width: 25.2%; height: 72%;"><img class="object-cover w-full h-full" src="https://images.unsplash.com/photo-1581090464777-f3220bbe1b8b?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=350&amp;q=80" alt=""></div>-->
                <!--                <div class="absolute bg-blue-600" style="top: 12.8%; left: 70%; width: 25.2%; height: 72%;"><img class="object-cover w-full h-full" src="https://images.unsplash.com/photo-1535223289827-42f1e9919769?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=668&amp;q=80" alt=""></div>-->
                <img class="relative mx-auto w-3/5 lg:w-4/5" src="../assets/imgs/illustrations/email.svg" alt="email">
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="container mx-auto invisible">
      <h4 class="text-2xl mb-2 leading-tight font-bold font-heading text-center">Still couldn't find an answer?</h4>
      <h1 class="text-4xl mb-2 leading-tight font-bold font-heading text-center">Send a support request to:</h1>
      <div>
        <h1 class="text-blue-600 text-3xl mb-2 leading-tight font-bold font-heading text-center">
          <a target="_blank"
             href="mailto:support.fitt@psychai.co?subject=Support request from fitt faq website&body=Good day,%0D%0A%0D%0ACan you please assist with the following:%0D%0A%0D%0A">support.fitt@psychai.co</a>
        </h1>
      </div>
    </div>

    <section class="py-16">
      <div class="container px-4 mx-auto">
        <div class="flex flex-col lg:flex-row mb-10">
          <a class="inline-block mx-auto mb-10 lg:mb-0 lg:ml-0 lg:mr-auto text-3xl font-semibold leading-none"
             href="#"><img class="h-10" src="../assets/imgs/logo.png" alt="" width="auto"></a>
          <ul class="flex lg:flex-row items-center justify-center space-x-12 ">
            <li><a class="font-bold font-heading hover:text-blueGray-600 text-xs md:text-lg" href="#"
                   v-scroll-to="'#whatisfitt'"><p class="text-center md:text-left">WHAT IS FITT?</p></a></li>
            <li><a class="font-bold font-heading hover:text-blueGray-600 text-xs md:text-lg" href="#"
                   v-scroll-to="'#faqs'"><p class="text-center md:text-left">FAQs</p></a></li>
            <li><a class="font-bold font-heading hover:text-blueGray-600 text-xs md:text-lg" href="#"
                   v-scroll-to="'#contact'"><p class="text-center md:text-left">CONTACT US</p></a></li>
            <li><a class="font-bold font-heading hover:text-blueGray-600 text-xs md:text-lg" href="https://fitt.ai"><p
                class="text-center md:text-left">FITT WEBSITE</p></a></li>
          </ul>
        </div>
        <div class="flex flex-col lg:flex-row items-center lg:justify-between">
          <p class="text-xs text-blueGray-400">&copy; {{new Date().getFullYear()}}. All rights reserved.</p>
          <!--            <div class="hidden order-first lg:order-last -mx-2 mb-4 lg:mb-0"><a class="inline-block px-2" href="#">-->
          <!--              <img src="../assets/imgs/icons/facebook-blue.svg" alt=""></a><a class="inline-block px-2" href="#">-->
          <!--              <img src="../assets/imgs/icons/twitter-blue.svg" alt=""></a><a class="inline-block px-2" href="#">-->
          <!--              <img src="../assets/imgs/icons/instagram-blue.svg" alt=""></a>-->
          <!--            </div>-->
        </div>
      </div>
    </section>

  </div>
</template>

<script>
  import Snackbar from 'node-snackbar';
  import 'node-snackbar/dist/snackbar.css';

  export default {
    name: 'Website',
    props: {},
    mixins: [],
    data() {
      return {
        // api:'http://fitt-management.test/api/v1/support',
        api: 'https://manage.fitt.ai/api/v1/support',
        searchTerm: '',
        faqGroup: null,
        ignoreList: [
          'my', 'i', 'want', 'the', 'a', 'an', 'we', 'us', 'can'
        ],
      }
    },
    computed: {
      filteredFaqs() {

        if (!this.faqGroup || !this.faqGroup.faqs) {
          return [];
        }

        if (!this.searchTerm || this.searchTerm.trim() === '') {
          this.faqGroup.faqs.forEach(val => val.open = false);
          return this.faqGroup.faqs;
        }

        const searchTermArray = this.searchTerm.toLowerCase().trim().split(" ").filter(term => term.length > 1 && !this.ignoreList.includes(term));

        if (searchTermArray.length < 1) {
          this.faqGroup.faqs.forEach(val => val.open = false);
          return this.faqGroup.faqs;
        }

        //Todo add a search rank and add the faq with most matches at the top and go downward from there

        return this.faqGroup.faqs.filter(faq => {
          let questionArrayOfWords = faq.question.toLowerCase().split(" ").filter(question => !this.ignoreList.includes(question));

          if (questionArrayOfWords.filter(word => searchTermArray.filter(term => word.replace(/(<([^>]+)>)/gi, '').indexOf(term) !== -1).length > 0).length > 0) {
            // if (questionArrayOfWords.filter(word => searchTermArray.filter(term => word.replace(/(<([^>]+)>)/gi, '').indexOf(term) !== -1).length > 0).length === searchTermArray.length) {
            faq.open = true;
            return true;
          }
        });
      }
    },
    methods: {
      toggleFaq(idx) {
        this.faqGroup.faqs[idx].open = !this.faqGroup.faqs[idx].open;
      },
      toggleFaqId(id) {
        if(!this.faqGroup || !this.faqGroup.faqs || this.faqGroup.faqs.length < 1) return;

        let idx = this.faqGroup.faqs.findIndex(faq => faq.faqid == id);

        if(!idx || idx < 0) return;

        this.toggleFaq(idx);
      },
      highlightSearchTerm(text, color = '#ffe7ee') {
        if (!text || text.length < 2) return text;

        let highlightHtml = `<span style="background-color: ${color}">$1</span>`;

        if (this.searchTerm && this.searchTerm.trim() !== '') {
          const terms = this.searchTerm.toLowerCase().trim().split(" ").filter(term => term.length > 1 && !this.ignoreList.includes(term));

          terms.forEach(term => term.length > 1 ? text = text.replace(new RegExp('(' + term + ')', 'gi'), highlightHtml) : text);

        }

        return text;
      },
      getFaqs() {
        this.$http.get(this.api).then(
          (res) => {
            this.faqGroup = res.data;

            this.scrollToHashElement();

          },
          () => {
          },
        );
      },
      scrollToHashElement() {
        this._scrollCounter++;

        if (this._scrollCounter > 10) {
          return;
        }

        if (window.location.hash) {
          let id = window.location.hash.split('#').join('');
          let element = document.getElementById(id);
          if (!element) {
            setTimeout(()=>{
              this.scrollToHashElement();
            },300);
            return;
          }

          let top = element.offsetTop - 200;
          window.scrollTo({
            top: top,
            behavior: 'smooth',
          });

          this.toggleFaqId(id);
        }
      },
      openRightClickMenu(item){

        if (!navigator.clipboard) {
          return;
        }

        let url = window.location.origin + window.location.pathname + '#' +item.faqid;

        navigator.clipboard.writeText(url).then(function() {
          Snackbar.show({pos: 'top-right',text:'Link copied!', actionText:'OK'});

        }, function() {

        });
      }
    },
    mounted() {
      this._scrollCounter = 0;
      if (this.$route.params.client) {
        this.api += '/' + this.$route.params.client;
      }
      if (this.$route.params.project) {
        this.api += '/' + this.$route.params.project;
      }

      this.getFaqs();

      // open
      const burger = document.querySelectorAll('.navbar-burger');
      const menu = document.querySelectorAll('.navbar-menu');

      if (burger.length && menu.length) {
        for (let l = 0; l < burger.length; l++) {
          burger[l].addEventListener('click', function () {
            for (let j = 0; j < menu.length; j++) {
              menu[j].classList.toggle('hidden');
            }
          });
        }
      }

      // close
      const close = document.querySelectorAll('.navbar-close');
      const backdrop = document.querySelectorAll('.navbar-backdrop');

      if (close.length) {
        for (let i = 0; i < close.length; i++) {
          close[i].addEventListener('click', function () {
            for (let j = 0; j < menu.length; j++) {
              menu[j].classList.toggle('hidden');
            }
          });
        }
      }

      if (backdrop.length) {
        for (let k = 0; k < backdrop.length; k++) {
          backdrop[k].addEventListener('click', function () {
            for (let j = 0; j < menu.length; j++) {
              menu[j].classList.toggle('hidden');
            }
          });
        }
      }
    }
  }
</script>

<style scoped>
  button:focus {
    outline: 0;
  }
</style>
